@import "./assets/fonts/IBMPlexSans/stylesheet.css";

* {
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}
body,
html {
    font-size: 0.09765625vw;
    padding: 0;
    margin: 0;
}
body {
    background-color: #171718;
    color: #ffffff;
    font-family: "Ibmplexsans";
}

.icon{
    width: 16rem;
    height: 16rem;
}

@media only screen and (min-width: 1280px) {
    body,
    html {
        font-size: 1.25px;
    }
}
@media only screen and (max-width: 991px) {
    body,
    html {
        font-size: 0.13vw;
    }
}
@media only screen and (max-width: 767px) {
    body,
    html {
        font-size: 0.176vw;
    }
}
@media only screen and (max-width: 477px) {
    body,
    html {
        font-size: 0.3125vw;
    }
}
svg {
    object-fit: contain;
    display: block;
}
html.stopped {
    height: 100vh;
    overflow: hidden;
}

button {
    background: unset;
    color: unset;
    font-weight: unset;
}

.text--gradient-blue {
    background: linear-gradient(265.92deg, #00a3ff -5.66%, #00e1ef 103.85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.text--blue {
    color: #00e1ef;
}
.text--purple {
    color: #8946ff;
}
.text--green {
    color: #00ffa0;
}
.text--white {
    color: #ffffff;
}
.text--dark {
    color: #080117;
}
.text--grey {
    color: #9b96b0;
}
.text--100 {
    font-weight: 100;
}
.text--200 {
    font-weight: 200;
}
.text--300 {
    font-weight: 300;
}
.text--400 {
    font-weight: 400;
}
.text--500 {
    font-weight: 500;
}
.text--600 {
    font-weight: 600;
}
.text--700 {
    font-weight: 700;
}
.text--800 {
    font-weight: 800;
}
.text--900 {
    font-weight: 900;
}
.text_h--140 {
    line-height: 1.4;
}
.text_h--150 {
    line-height: 1.5;
}
.text_h--80 {
    line-height: 0.8;
}
.text_spc_1 {
    letter-spacing: 0.01em;
}
.text_spc_5 {
    letter-spacing: 0.05em;
}
.text_spc_-2 {
    letter-spacing: -0.02em;
}
.text_spc_-3 {
    letter-spacing: -0.03em;
}
.text_spc_-6 {
    letter-spacing: -0.06em;
}
.text--caps {
    text-transform: uppercase;
}
a {
    color: inherit;
    text-decoration: none;
    display: block;
}
img {
    display: block;
    max-width: none;
    object-fit: contain;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}
p {
    margin: 0;
}
form {
    margin: 0;
}
input {
    margin: 0;
}
label {
    font-size: 16rem;
}
.main {
    overflow: hidden;
}

/* CUSTOM */

h1 {
    letter-spacing: 0.003em;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 48rem;
    font-weight: 400;
    line-height: 1;
}

h3 {
    letter-spacing: 0.00666667em;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24rem;
    font-weight: 400;
    line-height: 1.29167;
}

h4 {
    letter-spacing: 0.00666667em;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24rem;
    font-weight: 400;
    line-height: 1.125;
}

p {
    margin-bottom: 0;
}

.global-styles {
    display: none;
}

.main {
    overflow: hidden;
}

.h1 {
    letter-spacing: 0.003em;
    font-size: 48rem;
    line-height: 1;
}

.text-3 {
    letter-spacing: 0.0133333em;
    font-size: 12rem;
    font-weight: 300;
    line-height: 1.33333;
}

.text-2 {
    letter-spacing: 0.0114286em;
    font-size: 14rem;
    font-weight: 300;
    line-height: 1.71429;
}

.text-1 {
    letter-spacing: 0.01em;
    font-size: 16rem;
    font-weight: 300;
    line-height: 1.33333;
}

@media screen and (max-width: 991px) {
    h1 {
        font-size: 32rem;
    }

    h4 {
        font-size: 16rem;
    }

    .h1 {
        font-size: 32rem;
    }
}



@media screen and (max-width: 479px) {
    .generate__widget-item {
        width: 116rem;
        height: 116rem;
    }
}
