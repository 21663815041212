.flow__wrapper {
    max-width: 1280px;
    grid-row-gap: 32rem;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 48rem 24rem;
    display: flex;
    .flow__list {
        grid-column-gap: 16px;
        grid-row-gap: 32rem;
        flex-direction: column;
        grid-template-rows: auto auto auto;
        grid-template-columns: 1fr;
        grid-auto-columns: 1fr;
        display: grid;
        .flow__step {
            grid-column-gap: 16rem;
            grid-template-rows: auto;
            grid-template-columns: 314rem 1fr;
            grid-auto-columns: 1fr;
            display: grid;
            .flow__step-image {
                height: 180rem;
            }

            .flow__step-info {
                grid-row-gap: 8rem;
                flex-direction: column;
                align-items: flex-start;
                display: flex;
                .flow__step-para {
                    max-width: 320rem;
                }
                .flow__step-headline {
                    grid-row-gap: 8rem;
                    flex-direction: column;
                    align-items: flex-start;
                    display: flex;
                    .flow__step-label {
                        color: #2d7dff;
                    }
                    .flow__step-title {
                        max-width: 515rem;
                    }
                }
            }
        }
    }
    .flow__headline {
        justify-content: space-between;
        align-items: center;
        display: flex;
        .flow__heading {
            width: 300rem;
        }

        .flow__arrow {
            width: 98rem;
            height: 98rem;
        }
    }
}
@media screen and (max-width: 991px) {
    .flow__list {
        grid-row-gap: 24rem;
        justify-content: start;
        justify-items: stretch;
        .flow__arrow {
            width: 80rem;
            height: 80rem;
        }

        .flow__step-title {
            width: auto;
        }
    }
}
@media screen and (max-width: 767px) {
    .flow__wrapper {
        grid-row-gap: 24rem;
        padding: 32rem 16rem;
        .flow__list {
            justify-content: space-between;
            .flow__step {
                grid-row-gap: 16rem;
                grid-template-columns: 1fr;
                .flow__step-image {
                    width: calc(100vw - 32rem);
                    height: calc((100vw - 32rem) * 0.5729323308);
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .flow__arrow {
        width: 40rem;
        height: 40rem;
    }
}
