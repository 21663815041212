.hero {
    overflow: hidden;
    position: relative;
    z-index: 0;
    .hero__background {
        position: absolute;
        top: 0%;
        left: 0%;
        right: 0%;
        bottom: 0%;
        width: 100%;
        height: 100%;
        z-index: 0;
        .hero__overlay {
            position: absolute;
            top: 0%;
            left: 0%;
            right: 0%;
            bottom: 0%;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: rgba(23, 23, 24, 0.6);
            display: none;
        }
        .hero__blur {
            position: absolute;
            z-index: 0;
            height: 1189rem;
            width: 1189rem;
            object-fit: contain;
            left: 50%;
            bottom: 50%;
            transform: translate(-50%, 50%) translateY(100rem);
        }
    }

    .hero__wrapper {
        height: 565rem;
        max-width: 1280px;
        grid-column-gap: 16rem;
        grid-template-rows: auto;
        grid-template-columns: 480rem 1fr;
        grid-auto-columns: 1fr;
        align-content: end;
        justify-content: space-between;
        align-items: end;
        margin-left: auto;
        margin-right: auto;
        padding: 58rem 24rem 28rem;
        display: grid;
        position: relative;
        z-index: 1;

        .hero__info {
            grid-row-gap: 24rem;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            display: flex;
            position: relative;
            z-index: 2;
            .hero__headline {
                grid-row-gap: 24rem;
                flex-direction: column;
                display: flex;
                .hero__para {
                    width: 398rem;
                }
            }

            .hero__cta {
                grid-column-gap: 16rem;
                grid-template-rows: auto;
                grid-template-columns: 150rem 150rem;
                grid-auto-columns: 1fr;
                display: grid;
            }
        }
        .hero__visual {
            grid-column-gap: 16rem;
            grid-row-gap: 16rem;
            flex-direction: column;
            grid-template-rows: 1fr 1fr 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-columns: 1fr;
            justify-content: center;
            display: flex;
            justify-self: stretch;
            position: relative;
            z-index: 2;
            .hero__grid {
                grid-column-gap: 16rem;
                grid-row-gap: 16rem;
                flex: 1;
                grid-auto-flow: column;
                grid-template-rows: auto auto auto;
                grid-template-columns: auto auto auto;
                grid-auto-columns: 1fr;
                align-content: end;
                justify-content: end;
                align-self: flex-end;
                align-items: end;
                justify-items: end;
                display: grid;
                .hero__grid-item {
                    width: 149rem;
                    height: 149rem;
                    position: relative;
                    .hero__grid-item-placeholder {
                        position: absolute;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                    .hero__grid-item-image {
                        position: absolute;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        opacity: 0;
                    }
                    .hero__grid-item-overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        box-shadow: 0px 0 0px 1px rgba(255, 255, 255, 1) inset;
                        z-index: 2;
                        position: relative;
                        .hero__grid-item-overlay-tag {
                            position: absolute;
                            right: 0%;
                            top: 0%;
                            background-color: #ffffff;
                            padding: 6rem 8rem;
                            color: #0062ff;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .hero {
        .hero__wrapper {
            height: auto;
            grid-row-gap: 48rem;
            grid-template-columns: 1fr;
            align-items: center;
            justify-items: center;
            padding-top: 140rem;
            .hero__info {
                width: 500rem;
                text-align: center;
                justify-content: center;
                align-items: center;
                .hero__headline {
                    justify-content: center;
                    align-items: center;
                }
            }
            .hero__visual {
                justify-self: center;
                .hero__grid {
                    align-self: auto;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .hero {
        .hero__wrapper {
            padding-top: 120rem;
            padding-left: 16rem;
            padding-right: 16rem;
            .hero__visual {
                justify-self: stretch;
                .hero__grid {
                    justify-content: stretch;
                    justify-items: stretch;
                    .hero__grid-item {
                        width: auto;
                        height: 155rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 479px) {
    .hero {
        .hero__wrapper {
            padding-top: 68rem;
            .hero__info {
                width: auto;
                justify-content: flex-start;
                align-items: stretch;
                .hero__headline {
                    text-align: left;
                    justify-content: flex-start;
                    align-items: flex-start;
                    .hero__para {
                        width: auto;
                    }
                }

                .hero__cta {
                    grid-template-columns: 1fr 1fr;
                }
            }
            .hero__visual {
                .hero__grid {
                    grid-column-gap: 12rem;
                    grid-row-gap: 12rem;
                    .hero__grid-item {
                        height: 88rem;
                    }
                }
            }
        }
    }
}
