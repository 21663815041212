.demo {
    background-color: #6929c4;

    .demo__wrapper {
        max-width: 1280px;
        grid-row-gap: 116rem;
        flex-direction: column;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        padding: 32rem 24rem 28rem;
        display: flex;

        .demo__info {
            grid-column-gap: 16rem;
            grid-template-rows: auto;
            grid-template-columns: 1fr 1fr;
            grid-auto-columns: 1fr;
            display: grid;
            .demo__para {
                width: 400rem;
            }
        }
        .demo__try {
            grid-row-gap: 40rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            display: flex;
            .demo__widget {
                grid-column-gap: 16rem;
                grid-template-rows: auto;
                grid-template-columns: 1fr;
                grid-auto-columns: 1fr;
                align-self: stretch;
                display: grid;
                .demo__upload {
                    grid-row-gap: 16rem;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 16rem 12rem;
                    display: flex;
                    .file-upload {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        .demo__upload-box {
                            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F4F4F433' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
                            transition: 200ms ease background-image;
                            flex-direction: column;
                            flex: 1;
                            justify-content: center;
                            align-self: stretch;
                            align-items: center;
                            display: flex;
                            .demo__upload-overlay {
                                color: rgba(244, 244, 244, 0.6);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                row-gap: 0rem;
                                flex-direction: column;
                                padding-bottom: 10rem;
                                transition: 200ms ease color;
                                .upload-gif {
                                    width: 150rem;
                                    height: 156rem;
                                    opacity: 0.35;
                                    margin-bottom: -10rem;
                                    pointer-events: none;
                                    transition: 200ms ease opacity;
                                }
                            }
                        }
                        &:hover,
                        &.active {
                            .demo__upload-box {
                                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='1' stroke-dasharray='4%2c 4' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
                                .demo__upload-overlay {
                                    color: #f4f4f4;
                                    .upload-gif {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                }
                .demo__example {
                    grid-row-gap: 16rem;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    padding: 16rem 12rem;
                    display: flex;

                    .demo__example-list {
                        grid-column-gap: 12rem;
                        grid-row-gap: 12rem;
                        grid-template-rows: auto auto;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-auto-columns: 1fr;
                        align-self: stretch;
                        display: grid;
                    }
                }
            }
        }
    }
}
.generate {
    background-color: #000;
    .generate__wrapper {
        max-width: 1280px;
        grid-row-gap: 18rem;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        padding: 40rem 24rem 28rem;
        display: flex;
        .generate__headline {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            display: flex;
            .generate__close {
                grid-column-gap: 8rem;
                justify-content: flex-end;
                align-items: center;
                display: flex;
                cursor: pointer;
                .generate__x {
                    width: 12rem;
                    height: 12rem;
                }
            }
        }
        .generate__widget {
            grid-row-gap: 18rem;
            flex-direction: column;
            justify-content: flex-end;
            display: flex;
            .generate__widget-view {
                grid-column-gap: 16rem;
                grid-template-rows: auto;
                grid-template-columns: 1fr 1fr;
                grid-auto-columns: 1fr;
                display: grid;
                .generate__widget-preview {
                    background-image: url('../../assets/images/demo/background-pattern.png');
                    object-fit: contain;
                    background-position: 50% 50%;
                    position: relative;
                    .generate__widget-preview-item {
                        z-index: 2;
                        position: absolute;
                        top: 0%;
                        bottom: 0%;
                        left: 0%;
                        right: 0%;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .generate__widget-preview-background {
                        z-index: 1;
                        position: absolute;
                        top: 0%;
                        bottom: 0%;
                        left: 0%;
                        right: 0%;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .generate__widget-select {
                    overflow: hidden;
                    grid-row-gap: 16rem;
                    flex-direction: column;
                    padding: 16rem 12rem;
                    display: flex;
                    position: relative;
                    background: linear-gradient(61.3deg, rgba(255, 255, 255, 0.2) 5.87%, rgba(255, 255, 255, 0.042) 94.03%);
                    .generate__widget-examples {
                        overflow: hidden;
                        .generate__widget-track {
                            height: 416rem;
                            overflow: hidden;
                            .generate__widget-list {
                                grid-column-gap: 12rem;
                                grid-row-gap: 12rem;
                                grid-template-rows: 1fr 1fr;
                                grid-template-columns: 1fr 1fr 1fr;
                                grid-auto-rows: 1fr;
                                grid-auto-columns: 1fr;
                                grid-auto-flow: row;
                                display: grid;
                                overflow: hidden;
                                height: 100%;
                            }
                        }
                    }
                    .generate__widget-variations {
                        overflow: hidden;
                        .generate__widget-track {
                            height: 416rem;
                            overflow: hidden;
                            .generate__widget-variations-list {
                                padding-right: 64rem;
                                grid-column-gap: 12rem;
                                grid-row-gap: 12rem;
                                grid-template-rows: 1fr 1fr;
                                grid-template-columns: 1fr 1fr;
                                grid-auto-rows: 1fr;
                                grid-auto-columns: 1fr;
                                grid-auto-flow: row;
                                display: grid;
                                overflow: hidden;
                            }
                        }
                    }
                    .generate__widget-return {
                        cursor: pointer;
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: row;
                        align-items: center;
                        column-gap: 8rem;
                    }
                }
            }
            .generate__widget-cta {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
@media screen and (min-width: 992px) {
    .demo__wrapper {
        .demo__para {
            grid-area: 1 / 2 / 2 / 3;
        }
        .demo__upload,
        .demo__example {
            background: linear-gradient(61.3deg, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0.042) 94.03%);
        }
    }

    .generate__widget-track {
        overflow-y: auto;
        overflow-x: hidden;
    }
}
@media only screen and (max-width: 991px) {
    .demo {
        .demo__wrapper {
            grid-row-gap: 48rem;
            .demo__info {
                grid-template-columns: 1fr;
                display: block;
                .demo__para {
                    width: auto;
                }
            }
            .demo__try {
                align-items: flex-start;

                .demo__widget {
                    grid-row-gap: 0rem;
                    grid-template-columns: 1fr;
                    background: linear-gradient(61.3deg, rgba(0, 0, 0, 0.2) 5.87%, rgba(0, 0, 0, 0.042) 94.03%);
                    .demo__upload {
                        padding-left: 16rem;
                        padding-right: 16rem;
                        .demo__upload-box {
                            height: 32vw;
                            flex: 0 auto;
                        }
                    }
                    .demo__example {
                        padding-left: 16rem;
                        padding-right: 16rem;

                        .demo__example-list {
                            grid-column-gap: 16rem;
                            grid-template-rows: auto;
                            grid-template-columns: 1fr 1fr 1fr 1fr;
                            .demo__example-item {
                                width: auto;
                                height: 160rem;
                                &:nth-child(1n + 5) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .generate {
        .generate__wrapper {
            .generate__widget {
                .generate__widget-view {
                    grid-row-gap: 24rem;
                    grid-template-columns: 1fr;
                    .generate__widget-preview {
                        height: calc(100vw - 32rem);
                    }

                    .generate__widget-select {
                        padding-left: 16rem;
                        padding-right: 16rem;
                        .generate__widget-examples {
                            .generate__widget-track {
                                overflow-y: hidden;
                                overflow-x: auto;
                                .generate__widget-list {
                                    grid-column-gap: 16rem;
                                    grid-row-gap: 16rem;
                                    grid-template-columns: 1fr 1fr 1fr 1fr;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .generate {
        .generate__wrapper {
            .generate__widget {
                .generate__widget-view {
                    .generate__widget-select {
                        .generate__widget-examples {
                            .generate__widget-track {
                                .generate__widget-list {
                                    .example-item {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .demo {
        .demo__wrapper {
            padding-left: 16rem;
            padding-right: 16rem;

            .demo__try {
                .demo__widget {
                    .demo__upload {
                        .file-upload {
                            .demo__upload-box {
                                .demo__upload-overlay {
                                    .upload-gif {
                                        width: 48rem;
                                        height: 64rem;
                                    }
                                }
                            }
                        }
                    }

                    .demo__example {
                        .demo__example-list {
                            grid-template-columns: 1fr 1fr;
                            .demo__example-item:nth-child(1n + 4) {
                                display: none;
                            }
                            .demo__example-item {
                                height: 155rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .generate {
        .generate__wrapper {
            padding: 32rem 16rem 24rem;
            .generate__widget {
                .generate__widget-view {
                    flex-direction: column;
                    display: flex;
                    .generate__widget-select {
                        .generate__widget-examples {
                            .generate__widget-track {
                                height: auto;
                                flex-direction: row;
                                display: flex;
                                .generate__widget-list {
                                    grid-template-rows: 1fr;
                                    grid-template-columns: 1fr 1fr 1fr;
                                    grid-auto-flow: column;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .demo {
        .demo__wrapper {
            .demo__try {
                .demo__widget {
                    .demo__example {
                        .demo__example-list {
                            grid-template-columns: 1fr 1fr;
                            column-gap: 0rem;
                            row-gap: 12rem;
                            justify-content: center;
                            justify-items: center;
                            .demo__example-item {
                                height: 116rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .generate {
        .generate__wrapper {
            .generate__widget {
                .generate__widget-view {
                    .generate__widget-select {
                        .generate__widget-examples {
                            .generate__widget-track {
                                .generate__widget-list {
                                    .generate__widget-item {
                                        width: 116rem;
                                        height: 116rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
