@font-face {
    font-family: "Ibmplexsans";
    src: url("../IBMPlexSans/IBMPlexSans-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Ibmplexsans";
    src: url("../IBMPlexSans/IBMPlexSans-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Ibmplexsans";
    src: url("../IBMPlexSans/IBMPlexSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
