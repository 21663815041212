.loading-image {
    position: relative;
    height: 100%;
    width: 100%;
    .image-loader {
        position: absolute;
        z-index: 2;
        top: 0%;
        left: 0%;
        right: 0%;
        bottom: 0%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(2rem);
        -webkit-backdrop-filter: blur(2rem);
        .image-loader-gif {
            width: 72rem;
            height: 72rem;
        }
    }
}
